import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet"
import Sticky from 'react-stickynode';
/**
 * Components
 */
import ChatForm from '@components/forms/chat-form';
import ReviewRow from '@components/review-row/review-row';
import Sidebar from '@components/sidebar/sidebar';
import ReviewLine from '@components/review-line/review-line';
import ContactBox from '@components/contact-box/contact-box';
import { Link } from 'gatsby';
import ElfsightGoogleWidget from "../Elfsight/ElfsightGoogleWidgetModule";

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
/**
 * Assets
 */
import './styles/_index.scss';

const StaticReviews = (props) => {
    //console.log("props", props);
    const [renderComponent, setRenderComponent] = useState(false);

    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
    }, [])
    if (typeof window !== "undefined") {
        (function (w, d, s, o, f, estas) {
            w['ESTAS-Widget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
            let js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }
            (window, document, 'script', 'estas', 'https://www.theestas.com/assets/js/min/widget.bundle.js'));
    }
    const data = useStaticQuery(graphql`
        query {
        avatar: file(relativePath: { eq: "avatar.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    return (
        <div>
            <div className="property-fixed-bar footer-cta d-lg-none">
                <Container>
                    <Row className="justify-content-between">
                        <Col md="12" lg="auto">
                            <div className="fixed-utils">
                                <Link href="/contact/our-offices/" className="btn">Contact Us</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {renderComponent &&
                <>
                    <Helmet
                        script={[{
                            type: 'text/javascript',
                            innerHTML: "(function (w,d,s,o,f,js,fjs) {w['ESTAS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);}(window, document, 'script', 'estas', 'https://www.theestas.com/assets/js/min/widget.bundle.js'));estas('init', { el: 'estas-widget', key: 'qayro8ImsRiW2xXhxJoabXNVgLYnOG5flqiJ34WhdLQ7WtXOg1rs1Cos6B8E', showComments: true });"
                        }]} />
                </>
            }
            <Breadcrumbs />
            <div className="static-reviews">

                <Container>
                    <Row className="justify-content-between">
                        <Col lg="8">
                            <div className="static-review-wraper">
                                <ElfsightGoogleWidget WidgetID={props?.WidgetID} OfficeList={props?.OfficeList} />
                                <div id="estas-widget"></div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <Sticky top={200} bottomBoundary={2000} enableTransforms={false}>
                                <Sidebar>
                                    <ChatForm />
                                </Sidebar>
                            </Sticky>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}

export default StaticReviews
