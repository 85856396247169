import React, {useEffect, useState} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import { useMatch } from "@reach/router"
import NewsContactBox from "../contact-box/news-contact-box";

/**
 * Assets
 */
import './styles/_index.scss';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ContactBox from '@components/contact-box/contact-box';
import Sidebar from '@components/sidebar/sidebar';
import NewsletterForm from '@components/forms/newsletter-form';
import Sticky from 'react-stickynode';

const ContactQuery = (props) => {
    const [sidebar, setSidebar] = useState(true)
    const data = useStaticQuery(graphql`
        query {
        avatar: file(relativePath: { eq: "avatar.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    useEffect(()=>{
        if(document?.body){ 
            setTimeout(()=>{ 
                if(!document?.body?.className.includes("no-sidebar")){
                  //  setSidebar(true)
                }
            },100)
           
        }
    },[sidebar])

    const match = useMatch('/about/our-people/:item');
    return match ? (
        <empty></empty>
    ) : (
        <div className={`${props.layout == 'Static_Page' ? ' static-landing show-breadcrumbs spacing-bottom-only' : ''}`}>
            <div className={`contact-query static-intro breadcrumbs-intro`}>
                <Breadcrumbs />
                <Container className="static-page-content-wrap">
                    {props.Title &&
                        <Row>
                            <Col>
                                <div className="content-section-header">
                                    {props.Title && <h1 className="heading section-bottom-24">{props.Title}</h1>}
                                    {props?.StraplineText && <p>{props?.StraplineText}</p>}
                                </div>
                            </Col>
                        </Row>
                    }

                    {props.Alias == "report-a-maintenance" ?
                        <Row className="justify-content-between static-page-content">
                            <Col lg="12" className="left-cnt-sec">
                                <div className="static-content-wrap">
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content} />
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row className="justify-content-between static-page-content">
                            <Col lg={(sidebar) ? "8" : "12"} className="left-cnt-sec">
                                <div className="static-content-wrap">
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content} />
                                </div>
                                <div className="stickyremove"></div>
                            </Col>
                            {(sidebar) &&
                                <Col lg="4">
                                    <Sticky top={200} bottomBoundary='.stickyremove' enableTransforms={false}>
                                        <Sidebar className={`grey`}>
                                            {/* <NewsletterForm /> */}
                                            <div className="static-contact-box">
                                                <NewsContactBox phone="020 8882 6567" type={props.Alias == "lettings-fees" ? "rent" : "sale"} />
                                            </div>
                                            {props.data && props.data?.length > 0 &&
                                                <div className="sidebar-team-contact spacing-32 pb-0">
                                                    {props.data.map((data, key) => {
                                                        return <>
                                                            <ContactBox name={data.Name} phone={data.Phone} position={data.Designation} email={data.Email} link={data.Calendly_Link} linkText="Video Call" btnDefault={true} image={data.Image && data.Image.childImageSharp.fluid} />
                                                        </>
                                                    })}
                                                </div>
                                            }

                                        </Sidebar>
                                    </Sticky>

                                </Col>
                            }
                        </Row>
                    }
                </Container>
            </div>
        </div>
    )
}

export default ContactQuery
